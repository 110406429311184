import React from "react";
import Layout from "../../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductItemWithOdour from "../../components/productItemWithOdour";
import { graphql } from "gatsby";
const Thinner = ({ data }) => (
    <Layout>
        <Row>
            <Col>
                <h1>Thinner Paints</h1></Col>
        </Row>

        {/* NC thinner */}
        <ProductItemWithOdour
            heading={"NC Thinner"}
            data={"NC Thinner is used for thinning of nc paints for quick drying, dilute to spray-able consistency to give good finish."}
            package={"20 ltr,4ltr,1ltr"}
            color={"water white clear"}
            odour={"characteristic"}
            image={data.thinnerMain.childImageSharp.fluid}></ProductItemWithOdour>
        <hr />
        {/* epoxy thinner */}
        <ProductItemWithOdour
            heading={"Epoxy Thinner"}
            data={"Epoxy Thinner is used for thinning of epoxy paints for quick drying, dilute to spray-able consistency to give good finish."}
            package={"20 ltr,4ltr,1ltr"}
            color={"water white clear"}
            odour={"characteristic"}
            image={data.thinnerMain.childImageSharp.fluid}></ProductItemWithOdour>
        <hr />
        {/* gp thinner */}
        <ProductItemWithOdour
            heading={"GP Thinner"}
            data={"GP Thinner is used for thinning of enamel paints for quick drying, dilute to spray-able consistency to give good finish."}
            package={"20 ltr,4ltr,1ltr"}
            color={"water white clear"}
            odour={"characteristic"}
            image={data.thinnerMain.childImageSharp.fluid}></ProductItemWithOdour>
        <hr />
        {/* PU thinner */}
        <ProductItemWithOdour
            heading={"PU Thinner"}
            data={"2k Polyurethane Thinner is used for thinning of pu paints for quick drying, acrylic pu clear coats for bus body, chassis, etc."}
            package={"20 ltr,4ltr,1ltr"}
            color={"water white clear"}
            odour={"characteristic"}
            image={data.thinnerMain.childImageSharp.fluid}></ProductItemWithOdour>
    </Layout>
)

export default Thinner;
export const query = graphql`
  query {
    thinnerMain: file(relativePath: { eq: "thinnerMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`

